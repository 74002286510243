<template>
  <v-row justify="space-around" class="mb-2 mt-2">

    <!-- Cancel -->
    <v-btn @click="cancel">{{$t('common.buttons.cancel')}}</v-btn>

    <v-spacer />

    <!-- Save -->
    <v-btn
      :loading="isLoadingBtnSave"
      @click="submit"
      color="primary">
      {{$t('common.buttons.save')}}
    </v-btn>
  </v-row>
</template>

<script>
  export default
  {
    name: "ButtonBarFormDialog",

    props:
    {
      isLoadingBtnSave: {type: Boolean, default: false},
    },

    methods:
    {
      submit()
      {
        this.$emit('submit');
      },

      cancel()
      {
        this.$emit('cancel');
      }
    }
  }
</script>

<style scoped>

</style>